import { type MessageDescriptor, defineMessage } from 'react-intl';

import type { Country } from '../../services/i18n';
import { supportedCountries } from '../../services/i18n';

export const options: Record<Country, MessageDescriptor> = {
  AR: defineMessage({
    defaultMessage: 'Argentina',
    description: 'Country name for Argentina',
  }),
  AT: defineMessage({
    defaultMessage: 'Austria',
    description: 'Country name for Austria',
  }),
  AU: defineMessage({
    defaultMessage: 'Australia',
    description: 'Country name for Australia',
  }),
  BE: defineMessage({
    defaultMessage: 'Belgium',
    description: 'Country name for Belgium',
  }),
  BG: defineMessage({
    defaultMessage: 'Bulgaria',
    description: 'Country name for Bulgaria',
  }),
  BR: defineMessage({
    defaultMessage: 'Brazil',
    description: 'Country name for Brazil',
  }),
  CA: defineMessage({
    defaultMessage: 'Canada',
    description: 'Country name for Canada',
  }),
  CH: defineMessage({
    defaultMessage: 'Switzerland',
    description: 'Country name for Switzerland',
  }),
  CL: defineMessage({
    defaultMessage: 'Chile',
    description: 'Country name for Chile',
  }),
  CO: defineMessage({
    defaultMessage: 'Colombia',
    description: 'Country name for Colombia',
  }),
  CY: defineMessage({
    defaultMessage: 'Cyprus',
    description: 'Country name for Cyprus',
  }),
  CZ: defineMessage({
    defaultMessage: 'Czech Republic',
    description: 'Country name for Czech Republic',
  }),
  DE: defineMessage({
    defaultMessage: 'Germany',
    description: 'Country name for Germany',
  }),
  DK: defineMessage({
    defaultMessage: 'Denmark',
    description: 'Country name for Denmark',
  }),
  EE: defineMessage({
    defaultMessage: 'Estonia',
    description: 'Country name for Estonia',
  }),
  ES: defineMessage({
    defaultMessage: 'Spain',
    description: 'Country name for Spain',
  }),
  FI: defineMessage({
    defaultMessage: 'Finland',
    description: 'Country name for Finland',
  }),
  FR: defineMessage({
    defaultMessage: 'France',
    description: 'Country name for France',
  }),
  GB: defineMessage({
    defaultMessage: 'United Kingdom',
    description: 'Country name for United Kingdom',
  }),
  GR: defineMessage({
    defaultMessage: 'Greece',
    description: 'Country name for Greece',
  }),
  HK: defineMessage({
    defaultMessage: 'Hong Kong',
    description: 'Country name for Hong Kong',
  }),
  HU: defineMessage({
    defaultMessage: 'Hungary',
    description: 'Country name for Hungary',
  }),
  IE: defineMessage({
    defaultMessage: 'Ireland',
    description: 'Country name for Ireland',
  }),
  IT: defineMessage({
    defaultMessage: 'Italy',
    description: 'Country name for Italy',
  }),
  JP: defineMessage({
    defaultMessage: 'Japan',
    description: 'Country name for Japan',
  }),
  LT: defineMessage({
    defaultMessage: 'Lithuania',
    description: 'Country name for Lithuania',
  }),
  LU: defineMessage({
    defaultMessage: 'Luxemburg',
    description: 'Country name for Luxembourg',
  }),
  LV: defineMessage({
    defaultMessage: 'Latvia',
    description: 'Country name for Latvia',
  }),
  MT: defineMessage({
    defaultMessage: 'Malta',
    description: 'Country name for Malta',
  }),
  MX: defineMessage({
    defaultMessage: 'Mexico',
    description: 'Country name for Mexico',
  }),
  MY: defineMessage({
    defaultMessage: 'Malaysia',
    description: 'Country name for New Zealand',
  }),
  NL: defineMessage({
    defaultMessage: 'Netherlands',
    description: 'Country name for Netherlands',
  }),
  NO: defineMessage({
    defaultMessage: 'Norway',
    description: 'Country name for Norway',
  }),
  NZ: defineMessage({
    defaultMessage: 'New Zealand',
    description: 'Country name for New Zealand',
  }),
  PE: defineMessage({
    defaultMessage: 'Peru',
    description: 'Country name for Peru',
  }),
  PL: defineMessage({
    defaultMessage: 'Poland',
    description: 'Country name for Poland',
  }),
  PT: defineMessage({
    defaultMessage: 'Portugal',
    description: 'Country name for Portugal',
  }),
  RO: defineMessage({
    defaultMessage: 'Romania',
    description: 'Country name for Romania',
  }),
  SE: defineMessage({
    defaultMessage: 'Sweden',
    description: 'Country name for Sweden',
  }),
  SG: defineMessage({
    defaultMessage: 'Singapore',
    description: 'Country name for Singapore',
  }),
  SI: defineMessage({
    defaultMessage: 'Slovenia',
    description: 'Country name for Slovenia',
  }),
  SK: defineMessage({
    defaultMessage: 'Slovakia',
    description: 'Country name for Slovakia',
  }),
  US: defineMessage({
    defaultMessage: 'United States',
    description: 'Country name for United States',
  }),
} as const;

type UnTranslatedOption = {
  label: MessageDescriptor;
  value: Country;
};

export const getCountryOptions = (
  countries: Country[] = supportedCountries,
): UnTranslatedOption[] =>
  countries.map((c) => ({
    value: c,
    label: options[c],
  }));
